type MarkerType = 'color-a' | 'color-b' | 'color-c';

type MarkerId = string;

type MarkerPath = string;

type MarkerOffset = number;

type MarkerLength = number;

export type Marker = [MarkerType, MarkerId, MarkerPath, MarkerOffset, MarkerLength];

type MasterId = number;

export enum Source {
  search = 'search',
  searchLaw = 's_law',
  package = 'package',
  subscription = 'subscription',
  authorPackage = 'authorpackage',
  authorPackage2 = 'authorpackage2',
}

export type SourceTypes = Source.search;

interface Relation {
  title: string;
  body: string;
  blockIds: MasterId[];
  children: Relation[];
}

interface Case {
  number: string;
  external: string;
}

type Participant = string;

interface Attributes {
  commentary: string;
  title: string;
  attributes: {
    date: string;
    resolutionDate: string;
    case: Case;
    caseType: string;
    category: string;
    department: string;
    judge: string;
    result: string;
    appealState: string;
    plaintiffs: Participant[];
    defendants: Participant[];
    third: Participant[];
    others: Participant[];
  }
}

export interface ChronologyItem {
  documentId: number,
  title: string;
  department: string;
}

export interface SimilarItem {
  documentId: number,
  title: string;
  department: string;
}

interface Viewer {
  isOwner: boolean;
}

interface Block {
  body: string;
  id: string;
}

export interface DocumentBody {
  id: string;
  title: string;
  date: string;
  blocks: Block[];
}

interface DocumentAddition {
  id: string;
  title: string;
  description: string;
  blocks: Block[];
}

export type DocumentAdditions = DocumentAddition[];

export enum TabId {
  attributes = 'attributes',
  relations = 'relations',
  chronology = 'chronology',
  similar = 'similar',
}

export type TabIds = TabId.attributes | TabId.relations | TabId.chronology | TabId.similar;

interface DocumentView {
  id: number;
  isChronologyDocument: boolean;
  isSimilarDocument: boolean;
  source: SourceTypes;
  sourceId: number;
  sourceTitle: string;
  parentDocumentId: number;
  parentDocumentTitle: string;
  packagesCount: number;
  isShared: boolean;
  viewer: Viewer;
  document: {
    document: {
      isPractice: boolean;
      department: string;
      body: DocumentBody;
      additions: DocumentAdditions;
      loading: boolean;
    }
    entities: {
      markers: Marker[];
      loading: boolean;
    };
  };
  attributes: {
    data: Attributes;
    loading: boolean;
  };
  relations: {
    data: Relation[];
    loading: boolean;
  };
  chronology: {
    data: ChronologyItem[],
    loading: boolean;
  },
  similar: {
    data: SimilarItem[],
    loading: boolean,
  },
  layout: {
    sidebar: {
      activeTabId: TabIds;
    }
  }
}

export class StateBuilder {

  static createInitialDocument() {
    return {
      document: {
        isPractice: false,
        department: null,
        body: {
          id: null,
          title: null,
          date: null,
          blocks: [],
        },
        additions: [],
        loading: true,
      },
      entities: {
        markers: [],
        loading: true,
      },
    };
  }

  static createInitialAttributes() {
    return {
      data: {
        title: null,
        commentary: null,
        attributes: {
          date: null,
          resolutionDate: null,
          case: {
            number: null,
            external: null,
          },
          caseType: null,
          category: null,
          department: null,
          judge: null,
          result: null,
          appealState: null,
          plaintiffs: [],
          defendants: [],
          third: [],
          others: [],
        },
      },
      loading: true,
    };
  }

  static createInitialRelations() {
    return {
      data: [],
      loading: true,
    };
  }

  static createState(): DocumentView {
    return {
      id: null,
      isChronologyDocument: false,
      isSimilarDocument: false,
      isShared: false,
      source: null,
      sourceId: null,
      sourceTitle: null,
      parentDocumentId: null,
      parentDocumentTitle: null,
      packagesCount: 0,
      viewer: {
        isOwner: false,
      },
      document: StateBuilder.createInitialDocument(),
      attributes: StateBuilder.createInitialAttributes(),
      relations: StateBuilder.createInitialRelations(),
      chronology: {
        data: [],
        loading: true,
      },
      similar: {
        data: [],
        loading: true,
      },
      layout: {
        sidebar: {
          activeTabId: TabId.attributes,
        },
      },
    };
  }

}

export default StateBuilder;
