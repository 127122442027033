import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`

`;

style.Tabs = styled.div`

`;

style.Commentary = styled.div`
  padding: 14px 0 16px;
`;

style.Link = styled.div`
  position: relative;

  > div {
    padding: 8px 40px 7px 16px;
  }

  > a {
    position: absolute;
    top: 11px;
    right: 11px;
  }
`;

style.Loader = styled.div`
  height: 100%;
  position: relative;
  z-index: 2;

  > div {
    margin-top: 10px;
  }
`;

export default style;
