import React from 'react';

import s from './tabBar.style';

export type ItemId = string;

interface TabBarItemProps {
  onClick: (id: ItemId) => void;
  title: string | React.Component;
  activeId: ItemId;
  id: ItemId;
}

export const TabBarItem = (props: TabBarItemProps) => {
  const handleClick = () => {
    props.onClick(props.id);
  };

  const isActive = props.activeId === props.id;

  return <s.TabItem active={isActive} onClick={handleClick}>{props.title}</s.TabItem>;
};

export default TabBarItem;
